<template>
  <div id="operationBox">
    <!--面包屑-->
    <div class="breadcrumb"></div>
    <div class="content">
      <!--搜索条件 -->
      <div class="searchWrapper" @keydown.enter.prevent="searchList">
        <el-form
          :inline="true"
          status-icon
          label-position="left"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.region')" prop="areaId">
            <a-cascader ref="cascader" @change="parkClear"></a-cascader>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_operator')">
            <a-operation-select
              ref="operationSelect"
              @change="parkClear"
            ></a-operation-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
            <!-- <a-park-type-select
              ref="parkTypeSelect"
              @change="parkClear"
            ></a-park-type-select> -->
            <el-select v-model="formInline.parkType">
              <el-option label="全部" value=""></el-option>
              <el-option label="路内道路" :value="1"></el-option>
              <el-option label="路外车场" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.charge_type')">
            <el-select v-model="formInline.chargeType">
              <el-option label="计费1" value="1"></el-option>
              <el-option label="计费2" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Vehicle_ype')">
            <el-select v-model="formInline.chargeType">
              <el-option label="全部" value=""></el-option>
              <el-option label="不含无牌车" value="1"></el-option>
              <el-option label="无牌车" value="2"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="时间">
            <a-date-picker
              ref="datePicker"
              :selectkeys="selectkeys"
              :YearShowHidden="selectkeys[3]"
              :isPreviousday="isPreviousday"
            ></a-date-picker>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
            <!-- <el-input
              v-model="formInline.parkName"
              placeholder="请输入车场名称"
            ></el-input> -->
            <a-park-select
              ref="parkSelect"
              :instance="this"
              parkTypeRefName="parkTypeSelect"
              operationRefName="operationSelect"
              areaRefName="cascader"
            ></a-park-select>
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searchList();
            "
            :loading="loading"
            v-if="$route.meta.authority.button.query"
            >{{ $t('button.search') }}</el-button
          >
          <!-- <el-button
            type="primary"
            icon="el-icon-upload2"
            @click="exportFile"
            :loading="loading"
            v-if="$route.meta.authority.button.export"
            >{{ $t('button.export') }}</el-button
          > -->
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="empty()"
            :loading="loading"
            >{{ $t('button.reset') }}</el-button
          >
        </el-form>
      </div>
      <div class="list">
        <el-row :gutter="16">
          <el-col :span="16" class="list_left">
            <div class="divSize">
              <div>
                <div class="divIcon"></div>
                <span class="divText">停车营收汇总统计</span>
                <span class="div_time"
                  >时间范围: {{ startTimeShow }} 至 {{ endTimeShow }}</span
                >
              </div>
              <div class="status_H">
                <div class="status_info1">
                  <div></div>
                  <div class="status_info">
                    <div class="status_img">
                      <img src="./img/iconB.png" alt="" />
                    </div>
                    <div class="status_word">
                      <p>应收订单数（个）</p>
                      <p>
                        {{
                          totalPay.shouldpayNum ? totalPay.shouldpayNum : "0"
                        }}
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="status_info1">
                  <div></div>
                  <div class="status_info">
                    <div class="status_img">
                      <img src="./img/iconG.png" alt="" />
                    </div>
                    <div class="status_word">
                      <p>实收订单数（个）</p>
                      <p>
                        {{
                          totalPay.actualpayNum ? totalPay.actualpayNum : "0"
                        }}
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="status_info1">
                  <div></div>
                  <div class="status_info">
                    <div class="status_img">
                      <img src="./img/iconS.png" alt="" />
                    </div>
                    <div class="status_word">
                      <p>订单实缴率</p>
                      <p>
                        {{
                          totalPay.orderPayRadio
                            ? (totalPay.orderPayRadio * 100).toFixed(2)
                            : "0.00"
                        }}%
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="status_info1">
                  <div></div>
                  <div class="status_info">
                    <div class="status_img">
                      <img src="./img/iconF.png" alt="" />
                    </div>
                    <div class="status_word">
                      <p>客单价（元）</p>
                      <p>
                        {{
                          totalPay.avgPrice
                            ? priceFormate(totalPay.avgPrice)
                            : "0.00"
                        }}
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div class="status_H">
                <div class="status_info1">
                  <div></div>
                  <div class="status_info">
                    <!-- <div></div> -->
                    <div class="status_img">
                      <img src="./img/iconY.png" alt="" />
                    </div>
                    <div class="status_word">
                      <p>应收金额（元）</p>
                      <p>
                        {{
                          totalPay.shouldpay
                            ? priceFormate(totalPay.shouldpay)
                            : "0.00"
                        }}
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="status_info1">
                  <div></div>
                  <div class="status_info">
                    <div class="status_img">
                      <img src="./img/iconF.png" alt="" />
                    </div>
                    <div class="status_word">
                      <p>实收金额（元）</p>
                      <p>
                        {{
                          totalPay.actualpay
                            ? priceFormate(totalPay.actualpay)
                            : "0.00"
                        }}
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="status_info1">
                  <div></div>
                  <div class="status_info">
                    <div class="status_img">
                      <img src="./img/iconB.png" alt="" />
                    </div>
                    <div class="status_word">
                      <p>金额实缴率</p>
                      <p>
                        {{
                          totalPay.moneyPayRadio
                            ? (totalPay.moneyPayRadio * 100).toFixed(2)
                            : "0.00"
                        }}%
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="status_info1">
                  <div></div>
                  <div class="status_info">
                    <div class="status_img">
                      <img src="./img/iconG.png" alt="" />
                    </div>
                    <div class="status_word">
                      <p>平均泊位收入(元)</p>
                      <p>
                        {{
                          totalPay.avgBerthIncome
                            ? priceFormate(totalPay.avgBerthIncome)
                            : "0.00"
                        }}
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8" class="list_right">
            <div class="divSizeTop">
              <div class="padding16">
                <graphTitle :title="'道路/车场排行榜'"></graphTitle>
              </div>
              <parkTop
                @getranking="getranking"
                :parkTopList="parkTopList"
                :tabPanList="tabPanList"
              ></parkTop>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="12">
            <div class="chartSize1">
              <graphTitle :title="'应收订单数与实收订单数'" class="paddingSize">
                <el-tooltip
                  placement="top"
                  content="展示统计时间内，已出场的停车订单与已完成缴费的订单数量对比及趋势。按出场时间统计。未出场订单不参与统计。"
                >
                  <i class="el-icon-question my-icon" />
                </el-tooltip>
              </graphTitle>
              <received-order :type="1" :chartParam="data5"></received-order>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="chartSize1">
              <graphTitle :title="'应收金额与实收金额'" class="paddingSize">
                <el-tooltip
                  placement="top"
                  content="展示统计时间内，已出场的停车订单应收金额与实收金额的对比及趋势。实收金额=优惠金额+实付金额-退款金额。未出场订单不参与统计"
                >
                  <i class="el-icon-question my-icon" />
                </el-tooltip>
              </graphTitle>
              <received-order :type="2" :chartParam="data6"></received-order>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="12">
            <div class="chartSize">
              <graphTitle :title="'订单实缴率'">
                <el-tooltip
                  placement="top"
                  content="订单实缴率=实收订单数/应收订单数。该指标反应订单的实缴情况。指标越高，说明订单缴费情况越好。如一笔停车订单即有场中支付也有欠费，那么订单实缴率为0。"
                >
                  <i class="el-icon-question my-icon" />
                </el-tooltip>
              </graphTitle>
              <lineChart :orderData="data1"></lineChart>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="chartSize">
              <graphTitle :title="'金额实缴率'">
                <el-tooltip
                  placement="top"
                  content="金额实缴率=实收订单金额/应收订单金额。该指标反应订单的金额实缴情况。指标越高，说明订单缴费情况越好。"
                >
                  <i class="el-icon-question my-icon" />
                </el-tooltip>
              </graphTitle>
              <lineChart :orderData="data2"></lineChart>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="12">
            <div class="chartSize">
              <graphTitle :title="'客单价趋势分析'">
                <el-tooltip
                  placement="top"
                  content="客单价为统计范围内每笔停车订单的平均收费金额。客单价=订单应收金额/停车订单数。可辅助评估车场的收费标准。未出场订单不参与统计"
                >
                  <i class="el-icon-question my-icon" />
                </el-tooltip>
              </graphTitle>
              <lineChart :orderData="data3"></lineChart>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="chartSize">
              <graphTitle :title="'平均泊位收入趋势分析'">
                <el-tooltip
                  placement="top"
                  content="平均泊位收入=应收金额/泊位数量。反应统计范围内车场单个泊位的平均收费情况。该指标可集合实缴率一起评估车场的收费标准和收入情况。未出场订单不参与统计"
                >
                  <i class="el-icon-question my-icon" />
                </el-tooltip>
              </graphTitle>
              <lineChart :orderData="data4"></lineChart>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelNew, dateFormat, formatDuring } from "@/common/js/public";
import graphTitle from "@/components/graphTitle";
import parkTop from "@/components/parkingFeeAnalysis/parkTop";
import lineChart from "@/components/parkingFeeAnalysis/lineChart";
import receivedOrder from "@/components/parkingFeeAnalysis/receivedOrder";

export default {
  name: "parkingOperationAnalysis",
  components: {
    parkTop,
    graphTitle,
    lineChart,
    receivedOrder,
  },
  data() {
    const newDate = new Date();
    newDate.setTime(newDate.getTime() - 3600 * 1000 * 24);
    return {
      modelvalue: "",
      activeName: "1",
      isPreviousday: true,
      startTimeShow: "",
      endTimeShow: "",
      formInline: {
        parkId: "",
        parkName: "",
        showDate: "day",
        chargeType: "",
        parkType: "",
      },
      totalPay: {},
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < newDate;
        },
      },
      temParkData: {},
      selectkeys: ["date", "month", "week", "year"],
      value1: newDate,
      value2: "",
      value3: "",
      lastDay: 0,
      pageNum: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      data5: {
        legendData: ["应收订单数", "实收订单数"],
        unit: "单位：个",
        xData: [],
        yData: [],
        barData: [],
      },
      data6: {
        legendData: ["应收金额", "实收金额"],
        unit: "单位：元",
        xData: [],
        yData: [],
        barData: [],
      },
      data1: {
        unit: "",
        seriesName: "订单实缴率",
        Proportion: "%",
        yData: [],
        xData: [],
      },
      data2: {
        unit: "",
        seriesName: "金额实缴率",
        Proportion: "%",
        yData: [],
        xData: [],
      },
      data3: {
        unit: "金额(元)",
        seriesName: "客单价",
        Proportion: "",
        yData: [],
        xData: [],
      },
      data4: {
        unit: "金额(元)",
        seriesName: "平均泊位收入",
        Proportion: "",
        yData: [],
        xData: [],
      },
      company1: "金额（元）",
      Proportion: "%",
      parkTopList: [],
      tabPanList: [
        {
          label: this.$t("list.amount_receivable"),
          name: "1",
        },
        {
          label: "金额实缴率",
          name: "2",
        },
        {
          label: "客单价",
          name: "3",
        },
        {
          label: "平均泊位收入",
          name: "4",
        },
      ],
      orderType: "",
      rankType: "",
    };
  },
  methods: {
    parkClear() {
      // this.$refs.parkSelect.clear();
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    querySearchAsync(queryString, cb) {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
            areaIds: areaId,
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dataSource: this.$refs.parkTypeSelect
              ? this.$refs.parkTypeSelect.getParkType()
              : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkData = res.value.list;
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    empty() {
      this.areaId = "";
      this.streetId = "";
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.formInline.parkName = "";
      this.formInline.parkType = "";
      // this.$refs.parkTypeSelect.clear();
      this.$refs.parkSelect.clear();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    parkeEhartsList() {
      // /parkRevenueAnalysis/detailList
      this.$axios
        .get("/acb/2.0/aceParkAnalysis/revenue/trend", {
          data: {
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            parkType: this.formInline.parkType,
            dateType: this.$refs.datePicker
              ? this.$refs.datePicker.getDateType()
              : "",
            startTime: this.$refs.datePicker
              ? this.$refs.datePicker.getStartDate()
              : "",
            endTime: this.$refs.datePicker
              ? this.$refs.datePicker.getEndDate()
              : "",
            // parkName: this.formInline.parkName
            parkId: this.$refs.parkSelect
              ? this.$refs.parkSelect.getParkId()
              : "",
          },
        })
        .then((res) => {
          // this.parkTopList = res.value;
          let parkEchartsList = res.value;
          this.data1.xData = [];
          this.data1.yData = [];
          this.data2.xData = [];
          this.data2.yData = [];
          this.data3.xData = [];
          this.data3.yData = [];
          this.data4.xData = [];
          this.data4.yData = [];
          this.data5.xData = [];
          this.data5.barData = [];
          this.data5.yData = [];
          this.data6.xData = [];
          this.data6.barData = [];
          this.data6.yData = [];
          res.value.forEach((val) => {
            // 订单实缴率
            this.data1.xData.push(val.reportDate || "");
            this.data1.yData.push(
              val.orderPayRadio
                ? Number(val.orderPayRadio * 100).toFixed(2)
                : "0.00"
            );
            // 金额实缴率
            this.data2.xData.push(val.reportDate || "");
            this.data2.yData.push(
              val.moneyPayRadio
                ? Number(val.moneyPayRadio * 100).toFixed(2)
                : "0.00"
            );
            // 客单价
            this.data3.xData.push(val.reportDate || "");
            this.data3.yData.push(
              val.avgPrice ? Number(val.avgPrice / 100).toFixed(2) : "0.00"
            );
            // 平均泊位收入
            this.data4.xData.push(val.reportDate || "");
            this.data4.yData.push(
              val.avgBerthIncome
                ? Number(val.avgBerthIncome / 100).toFixed(2)
                : "0.00"
            );
            // 应收订单数与实收订单数
            this.data5.xData.push(val.reportDate || "");
            this.data5.yData.push(val.shouldpayNum || 0);
            this.data5.barData.push(val.actualpayNum || 0);
            console.log(this.data5, "hhhhhy");
            // 应收金额与实收金额
            this.data6.xData.push(val.reportDate || "");
            this.data6.yData.push(
              val.shouldpay ? Number(val.shouldpay / 100).toFixed(2) : "0.00"
            );
            this.data6.barData.push(
              val.actualpay ? Number(val.actualpay / 100).toFixed(2) : "0.00"
            );
            console.log(this.data6, "hhh9999hhy");
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 金额满三位显示逗号
    priceFormate(val) {
      if (val == 0) {
        return 0;
      } else if (val <= 9999999) {
        let price = Number(val / 100).toFixed(2);
        price =
          price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") +
          "." +
          price.split(".")[1];
        return price;
      } else {
        return val / 1000000 + "万";
      }
    },
    getranking(n, type) {
      this.rankType = n;
      // parkRevenueAnalysis / rank
      this.orderType = type;
      this.$axios
        .get("/acb/2.0/aceParkAnalysis/revenue/rank", {
          data: {
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            parkType: this.formInline.parkType,
            dateType: this.$refs.datePicker
              ? this.$refs.datePicker.getDateType()
              : "",
            startTime: this.$refs.datePicker
              ? this.$refs.datePicker.getStartDate()
              : "",
            endTime: this.$refs.datePicker
              ? this.$refs.datePicker.getEndDate()
              : "",
            // parkName: this.formInline.parkName,
            parkId: this.$refs.parkSelect
              ? this.$refs.parkSelect.getParkId()
              : "",
            rankType: n,
            orderType: type,
          },
        })
        .then((res) => {
          this.parkTopList = res.value;
          if (this.rankType == 1) {
            this.parkTopList.forEach((item) => {
              item.actualPay = item.shouldPay;
            });
          } else if (this.rankType == 2) {
            this.parkTopList.forEach((item) => {
              item.numberValue = item.moneyPayRadio;
            });
          } else if (this.rankType == 3) {
            this.parkTopList.forEach((item) => {
              item.actualPay = item.avgPrice;
            });
          } else if (this.rankType == 4) {
            this.parkTopList.forEach((item) => {
              item.actualPay = item.avgBerthIncome;
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchList() {
      this.searchData();
      this.getranking(this.rankType, this.orderType);
      this.parkeEhartsList();
    },
    searchData() {
      // /parkRevenueAnalysis/collect
      this.startTimeShow = this.$refs.datePicker.getStartDate();
      this.endTimeShow = this.$refs.datePicker.getEndDate();
      this.loading = true;
      this.$axios
        .get("/acb/2.0/aceParkAnalysis/revenue/collect", {
          data: {
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            parkType: this.formInline.parkType,
            parkId: this.$refs.parkSelect
              ? this.$refs.parkSelect.getParkId()
              : "",
            // dataSource: this.$refs.parkTypeSelect
            //   ? this.$refs.parkTypeSelect.getParkType()
            //   : "",
            dateType: this.$refs.datePicker
              ? this.$refs.datePicker.getDateType()
              : "",
            startTime: this.$refs.datePicker
              ? this.$refs.datePicker.getStartDate()
              : "",
            endTime: this.$refs.datePicker
              ? this.$refs.datePicker.getEndDate()
              : "",
            // parkName: this.formInline.parkName,
          },
        })
        .then((res) => {
          if (res.value) {
            this.totalPay = res.value;
          } else {
            this.totalPay = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeDateType() {},
    selectDay(selectDay) {
      this.lastDay = selectDay;
    },
    exportFile() {
      exportExcelNew("/acb/2.0/parkReport/analysisExport", {
        // streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        operationId: this.$refs.operationSelect
          ? this.$refs.operationSelect.getOperationId()
          : "",
        parkType: this.formInline.parkType,
        // dataSource: this.$refs.parkTypeSelect
        //   ? this.$refs.parkTypeSelect.getParkType()
        //   : "",
        dateType: this.$refs.datePicker
          ? this.$refs.datePicker.getDateType()
          : "",
        startTime: this.$refs.datePicker
          ? this.$refs.datePicker.getStartDate()
          : "",
        endTime: this.$refs.datePicker
          ? this.$refs.datePicker.getEndDate()
          : "",
        // parkName: this.formInline.parkName,
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        // reportType: reportType,
        // reportDate: this.formInline.time,
        // pageNum: this.pageNum,
        // pageSize: this.pageSize,
        // reportType: reportType,
        // reportDate: this.formInline.time,
      });
    },
    handleCurrentChange(currentpage) {
      this.pageNum = currentpage;
      this.searchData();
    },
  },
  mounted() {
    // console.log("-->$route.meta.authority", this.$route.meta.authority);
    this.searchList();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">

.list {
  margin-top: 16px;

  .divIcon {
    display: inline-block;
    position: absolute;
    width: 4px;
    height: 15px;
    background: #0c66ff;
  }

  // .list_left {
  // padding-right: 16px;
  // }
  .divSize {
    background: #fff;
    border-radius: 6px;
    height: 306px;
    padding: 16px 14px;
  }

  .divSizeTop {
    background: #fff;
    border-radius: 6px;
    height: 338px;
  }

  .padding16 {
    padding: 16px 0 0 16px;
  }

  .divText {
    margin-left: 12px;
    font-size: 14px;
  }

  .div_time {
    margin-left: 10px;
    color: rgba(51, 51, 51, 0.6);
    font-size: 12px;
  }
}

.paddingBottom {
  padding-bottom: 8px;
}

.status_H {
  margin-top: 24px;
  display: -webkit-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  // display: flex;
  .status_info {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    // justify-content: center;
    align-items: center;
    height: 116px;
    // padding: 0 2%;
    // padding: 0 8% 0 4%;
  }

  .status_info1 {
    display: grid;
    grid-template-columns: 2fr 14fr 3fr;
    background: rgba(82, 123, 255, 0.08);
    border-radius: 8px;
    margin: 0px 4px;
  }

  .status_word {
    height: 58px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .status_word p:first-child {
    color: #6C7293;
    font-size: 12px;
    line-height: 1.5;
  }

  .status_word p:last-child {
    color: #454458;
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
  }

  .status_img {
    padding-right: 12px;
  }

  .status_img img {
    width: 46px;
    height: 46px;
  }
}

.chartSize {
  height: 280px;
  border-radius: 8px;
  background: #fff;
  margin-top: 16px;
  padding: 16px;
}

.chartSize1 {
  border-radius: 8px;
  background: #fff;
  margin-top: 16px;
}

.paddingSize {
  padding: 17px 0px 5px 16px;
}

>>>.searchWrapper .el-form-item {
  margin-right: 30px;
}
</style>

