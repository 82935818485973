<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="activeName" class="tabs_head" @tab-click="handleTab">
        <el-tab-pane label="停车营收分析" name="1">
          <parkingRevenue
            ref="specialRecord"
            v-if="activeName == '1'"
          ></parkingRevenue>
        </el-tab-pane>
        <el-tab-pane label="车场营收明细" name="2">
          <parkingRevenueDetail
            v-if="activeName == '2'"
            ref="specialApply"
          ></parkingRevenueDetail>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import parkingRevenue from "./parkingRevenue.vue";
import parkingRevenueDetail from "./parkingRevenueDetail.vue";

export default {
  name: "payRepeat",
  components: {
    parkingRevenue,
    parkingRevenueDetail,
  },
  data() {
    return {
      authority: this.$route.meta.authority,
      activeName: "1",
    };
  },
  methods: {
    handleTab(vm) {
      //  vm.name == '1' ? this.$refs.specialRecord.searchList() : this.$refs.specialApply.searchData()
    },
  },
  created() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
>>>.tabs_head>.el-tabs__header {
  background: #fff;
  padding-left: 16px;
  margin: 16px 0 0 0;
}

>>>.el-tabs__item.is-active, >>>.el-tabs__item:hover {
  color: #527BFF;
}

>>>.el-tabs__active-bar {
  background-color: #527BFF;
}
</style>
